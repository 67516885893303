import React from 'react';
import './Trainers.css';
import KRU_BANK from '../assets/KRU_BANK.png';
import KRU_WAT from '../assets/KRU_WAT.png';
import KRU_KENG from '../assets/KRU_KENG.png';
import KRU_BUN from '../assets/KRU_BUN.jpeg';
import KRU_NUENG from '../assets/KRU_NUENG.png';

function Trainers() {
    return (
        <div className="trainers-container">
            <h1 id="trainers-section">OUR TRAINERS</h1>
            <h2>BOOK YOUR SESSION WITH OUR TRAINERS!</h2>

            <div className="trainers-display">
                <div className="trainer-card">
                    <img src={KRU_BANK} alt="Image of Kru Bank"/>
                    <p className="trainer-name">Kru Bank</p>
{/*
                    <button className="test-for-now ">Learn more</button>
                    //TODO Add "Learn more" button -> Go new page
*/}
                </div>

                <div className="trainer-card">
                    <img src={KRU_WAT} alt="Image of Kru Wat"/>
                    <p className="trainer-name">Kru Wat</p>
                </div>

                <div className="trainer-card">
                    <img src={KRU_NUENG} alt="Image of Kru Nueng"/>
                    <p className="trainer-name">Kru Nueng</p>
                </div>

                <div className="trainer-card">
                    <img src={KRU_KENG} alt="Image of Kru Keng"/>
                    <p className="trainer-name">Kru Keng</p>
                </div>

                {/*<div className="trainer-card">*/}
                {/*    <img src={KRU_BUN} alt="Image of Kru Bun"/>*/}
                {/*    <p className="trainer-name">Kru Ble</p>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Trainers;
